<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-modal v-model="isHandleOpenModalEditPhoto" :can-cancel="false">
    <div class="modal-content-edit-photo">
      <!-- Header -->
      <div class="modal-header">
        <div class="title-header">
          <div
            class="c-w-100 is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
          >
            <p class="averta-bold color-white label-20">Edit Foto</p>
          </div>
          <div
            @click="closeModalEdtitPhoto"
            class="c-w-100 is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center is-clickable"
          >
            <XMarkIcon size="20px" />
          </div>
        </div>
        <div class="alert-danger gap-2">
          <AlertIcon />
          <p class="averta-bold label-16 color-red">
            Foto Sebelum Berasio height {{ imageHeight }} x width {{ imageWidth }}. Foto Sesudah
            Harus Memiliki Rasio yang sama.
          </p>
        </div>
      </div>
      <!-- Header -->

      <!-- Body -->
      <div class="modal-body">
        <div class="content-body">
          <Cropper
            ref="cropper"
            class="cropper-section"
            :src="previewImage || preview_crop"
            :stencil-props="{
              aspectRatio: dynamicAspectRatio
            }"
            :canvas="{
              minHeight: imageHeight,
              minWidth: imageWidth,
              maxHeight: imageHeight,
              maxWidth: imageWidth
            }"
            :default-size="{
              width: imageWidth,
              height: imageHeight
            }"
            :style="style"
          />
        </div>
      </div>
      <!-- Body -->

      <!-- Footer -->
      <div class="modal-footer">
        <input
          type="file"
          ref="fileInput"
          hidden
          accept="image/png, image/jpeg"
          style="display: none"
          @change="handleFileChange"
        />
        <ButtonApp height="40px" width="200px" :isSecondary="true" @click="openFileGallery">
          <p class="averta-bold">Upload Ulang</p>
        </ButtonApp>
        <ButtonApp height="40px" width="200px" @click="cropImage">
          <p class="averta-bold">Simpan</p>
        </ButtonApp>
      </div>
      <!-- Footer -->
    </div>
  </b-modal>
</template>

<script>
import { AlertIcon, XMarkIcon } from '@/components/icons'
import { Cropper } from 'vue-advanced-cropper'
import ButtonApp from '@/components/button/ButtonApp.vue'
import discardImage from '@/assets/img/discard-data.png'
export default {
  name: 'ModalEditPhotoBeforeAfter',
  props: {
    isHandleOpenModalEditPhoto: {
      type: Boolean,
      default: false
    },
    previewImage: {
      type: String
    },
    closeModalEdtitPhoto: {
      type: Function,
      default: () => {}
    },
    imageId: {
      type: [Number, String],
      default: null
    },
    imageData: {
      type: Object,
      default: () => ({})
    },
    imageType: {
      type: String,
      default: null
    },
    imageHeight: {
      type: Number,
      default: 0
    },
    imageWidth: {
      type: Number,
      default: 0
    }
  },
  components: {
    ButtonApp,
    AlertIcon,
    Cropper,
    XMarkIcon
  },
  data () {
    return {
      stencilCoordinates: { height: 100, width: 100, left: 0, top: 0 },
      preview_crop: null
    }
  },
  computed: {
    style () {
      if (!this.stencilCoordinates) {
        return {}
      }
      const { height = 100, width = 100, left = 0, top = 0 } = this.stencilCoordinates
      return {
        width: `${width}%`,
        height: `${height}%`,
        transform: `translate(${left}px, ${top}px)`
      }
    },
    dynamicAspectRatio () {
      return this.imageWidth / this.imageHeight
    }
  },
  methods: {
    openFileGallery () {
      this.$refs.fileInput.click()
    },
    handleFileChange (e) {
      e.preventDefault()
      if (e.target.files[0]) {
        this.$emit('resetPreviewImage')
        this.preview_crop = URL.createObjectURL(e.target.files[0])
        console.log(this.preview_crop)
      }
    },
    handleConfirm () {
      this.$swal({
        imageUrl: discardImage,
        imageAlt: 'cancel offering',
        imageWidth: 500,
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: `<p class="averta-bold label-22" style="margin-bottom: -20px;">
                 Simpan Perubahan
                </p>`,
        html: '<p class="averta-reguler"> Pastikan Seluruh Informasi Yang Di Inginkan Sudah Sesuai </p>',
        showCancelButton: true,
        confirmButtonText: 'Simpan Perubahan',
        cancelButtonText: 'Batalkan',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.cropImage()
        }
      })
    },
    cropImage () {
      const result = this.$refs.cropper.getResult()
      result.canvas.toBlob((blob) => {
        const body = {
          preview: result.canvas.toDataURL('image/jpg'),
          file: new File([blob], 'cropped-image.png', { type: 'image/png' })
        }
        this.$emit('cropImage', body)
      }, 'image/png')
    }
  }
}
</script>

<style scoped>
.modal-content-edit-photo {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 800px;
  max-height: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden !important;
  border-radius: 25px;
}

.modal-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 150px;
  min-height: auto;
  max-height: 150px;
  justify-content: transparent;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  background: #f0f0f0;
  margin-bottom: auto;
}

.title-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: auto;
  max-height: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 2rem;
  background: #b40007;
  margin-bottom: auto;
}

.alert-danger {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: auto;
  max-height: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: 0 2rem;
  background: #ff00001a;
  z-index: 9999;
}

.modal-body {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: auto;
  max-height: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden !important;
  background: #f0f0f0;
}

.content-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: auto;
  max-height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden !important;
  background: transparent;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 80px;
  min-height: auto;
  max-height: 80px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 2rem;
  background: #ffffff;
  margin-top: auto;
}

.cropper-section {
  height: 100%;
  width: 100%;
  background: black;
}
</style>
