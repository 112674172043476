var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"can-cancel":false},model:{value:(_vm.isHandleOpenModalEditPhoto),callback:function ($$v) {_vm.isHandleOpenModalEditPhoto=$$v},expression:"isHandleOpenModalEditPhoto"}},[_c('div',{staticClass:"modal-content-edit-photo"},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"title-header"},[_c('div',{staticClass:"c-w-100 is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"},[_c('p',{staticClass:"averta-bold color-white label-20"},[_vm._v("Edit Foto")])]),_c('div',{staticClass:"c-w-100 is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center is-clickable",on:{"click":_vm.closeModalEdtitPhoto}},[_c('XMarkIcon',{attrs:{"size":"20px"}})],1)]),_c('div',{staticClass:"alert-danger gap-2"},[_c('AlertIcon'),_c('p',{staticClass:"averta-bold label-16 color-red"},[_vm._v(" Foto Sebelum Berasio height "+_vm._s(_vm.imageHeight)+" x width "+_vm._s(_vm.imageWidth)+". Foto Sesudah Harus Memiliki Rasio yang sama. ")])],1)]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"content-body"},[_c('Cropper',{ref:"cropper",staticClass:"cropper-section",style:(_vm.style),attrs:{"src":_vm.previewImage || _vm.preview_crop,"stencil-props":{
            aspectRatio: _vm.dynamicAspectRatio
          },"canvas":{
            minHeight: _vm.imageHeight,
            minWidth: _vm.imageWidth,
            maxHeight: _vm.imageHeight,
            maxWidth: _vm.imageWidth
          },"default-size":{
            width: _vm.imageWidth,
            height: _vm.imageHeight
          }}})],1)]),_c('div',{staticClass:"modal-footer"},[_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","hidden":"","accept":"image/png, image/jpeg"},on:{"change":_vm.handleFileChange}}),_c('ButtonApp',{attrs:{"height":"40px","width":"200px","isSecondary":true},on:{"click":_vm.openFileGallery}},[_c('p',{staticClass:"averta-bold"},[_vm._v("Upload Ulang")])]),_c('ButtonApp',{attrs:{"height":"40px","width":"200px"},on:{"click":_vm.cropImage}},[_c('p',{staticClass:"averta-bold"},[_vm._v("Simpan")])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }